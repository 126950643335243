<script>
import { api } from '@/global/services/api'

export default {
  name: 'Logout',

  data: () => ({
    fetching: false
  }),

  methods: {
    async logout () {
      try {
        this.fetching = true
        await api().base.get('/logout')

        await new Promise(() => setTimeout(() => window.location.reload(), 2000))
      }
      // Catch omitted, component error handler will handle it (show message, report to Bugsnag, etc.)
      finally {
        this.fetching = false
      }
    }
  },

  render () {
    return this.$scopedSlots.default({
      logout: this.logout,
      fetching: this.fetching
    })
  }
}
</script>
